import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "../src/assets/main.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import firebaseApp from "./assets/js/firebaseConfig";
import "./assets/css/common.css";
const hls = require("videojs-contrib-hls");
const bool =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const urlList = [
  "media.nova-beats.com",
  "media.novabeatsapp.com",
  "media.nova-beats.net",
  "media.nova-beatsapp.com",
  "media.novabeatsvip.com",
];
let appUrl = window.location.href; //`<%= VUE_APP_BASE_URL %>`; // 线上地址
let gtagID = "G-Q6BRJXQ3LV";
let adsClient = "8237884947473629";
let home_1_slot = "9090800263";
let home_2_slot = "2455469102";
let home_3_slot = "6751405947";
let home_4_slot = "6077452675";
let playlets_1_slot = "1142387432";
let playlets_2_slot = "7516224094";
let play_1_slot = "2138207669";
let play_2_slot = "2314473071";
switch (getHrefUrl(appUrl)) {
  case "media.nova-beats.com":
    gtagID = "G-Q6BRJXQ3LV";
    adsClient = "8237884947473629";
    home_1_slot = "9090800263";
    home_2_slot = "2455469102";
    home_3_slot = "6751405947";
    home_4_slot = "6077452675";
    playlets_1_slot = "1142387432";
    playlets_2_slot = "7516224094";
    play_1_slot = "2138207669";
    play_2_slot = "2314473071";
    break;
  case "media.novabeatsapp.com":
    gtagID = "G-N2TGCCXWJH";
    adsClient = "9786938146389549";
    home_1_slot = "5235144297";
    home_2_slot = "2369307807";
    home_3_slot = "5439329863";
    home_4_slot = "6011570732";
    playlets_1_slot = "7007390964";
    playlets_2_slot = "1330089693";
    play_1_slot = "7703926350";
    play_2_slot = "6356654279";
    break;
  case "media.nova-beats.net":
    gtagID = "G-B2FFMX2EG5";
    adsClient = "7480403109429724";
    home_1_slot = "3838473585";
    home_2_slot = "2068456294";
    home_3_slot = "2525391918";
    home_4_slot = "4047752376";
    playlets_1_slot = "7286592563";
    playlets_2_slot = "7129211282";
    play_1_slot = "4503047945";
    play_2_slot = "7745757568";
    break;
  case "media.nova-beatsapp.com":
    gtagID = "G-C81RVZGV1Y";
    adsClient = "2696334033428745";
    home_1_slot = "2920143583";
    home_2_slot = "2369307807";
    home_3_slot = "3970335526";
    home_4_slot = "6427309603";
    playlets_1_slot = "7718008845";
    playlets_2_slot = "5091845502";
    play_1_slot = "1056226130";
    play_2_slot = "2465682163";
    break;
  case "media.novabeatsvip.com":
    gtagID = "G-TM6R7BQJYN";
    adsClient = "8980316806770559";
    home_1_slot = "5618287679";
    home_2_slot = "7211576536";
    home_3_slot = "9400151401";
    home_4_slot = "9263024803";
    playlets_1_slot = "5629895968";
    playlets_2_slot = "4316814298";
    play_1_slot = "4151694157";
    play_2_slot = "2838612486";
    break;
}
function getHrefUrl(url) {
  let urlData = "";
  urlList.forEach((item) => {
    if (url.indexOf(item) > -1) {
      urlData = item;
    }
  });
  return urlData;
}
let adsbygoogleJs = document.createElement("script");
adsbygoogleJs.async = true;
adsbygoogleJs.type = "text/javascript";
adsbygoogleJs.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${adsClient}`;
document.getElementsByTagName("head")[0].appendChild(adsbygoogleJs);
console.log(
  getHrefUrl("media.novabeatsapp.com"),
  gtagID,
  adsClient,
  adsbygoogleJs
);

let ima3Script = document.createElement("script");
ima3Script.async = true;
ima3Script.type = "text/javascript";
ima3Script.src = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
document.getElementsByTagName("head")[0].appendChild(ima3Script);

let gptScript = document.createElement("script");
gptScript.async = true;
gptScript.crossorigin = "anonymous";
gptScript.type = "text/javascript";
gptScript.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
document.getElementsByTagName("head")[0].appendChild(gptScript);

let gtagScript = document.createElement("script");
gtagScript.async = true;
gtagScript.type = "text/javascript";
gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + gtagID;
document.getElementsByTagName("head")[0].appendChild(gtagScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", gtagID);

//Part1
!(function (w, d, t) {
  w.TiktokAnalyticsObject = t;
  var ttq = (w[t] = w[t] || []);
  (ttq.methods = [
    "page",
    "track",
    "identify",
    "instances",
    "debug",
    "on",
    "off",
    "once",
    "ready",
    "alias",
    "group",
    "enableCookie",
    "disableCookie",
  ]),
    (ttq.setAndDefer = function (t, e) {
      t[e] = function () {
        t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
      };
    });
  for (var i = 0; i < ttq.methods.length; i++)
    ttq.setAndDefer(ttq, ttq.methods[i]);
  (ttq.instance = function (t) {
    for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
      ttq.setAndDefer(e, ttq.methods[n]);
    return e;
  }),
    (ttq.load = function (e, n) {
      var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
      (ttq._i = ttq._i || {}),
        (ttq._i[e] = []),
        (ttq._i[e]._u = i),
        (ttq._t = ttq._t || {}),
        (ttq._t[e] = +new Date()),
        (ttq._o = ttq._o || {}),
        (ttq._o[e] = n || {});
      var o = document.createElement("script");
      (o.type = "text/javascript"),
        (o.async = !0),
        (o.src = i + "?sdkid=" + e + "&lib=" + t);
      var a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(o, a);
    });

  //Part 2
  if (window.location.host === "media.nova-beatsapp.com") {
    ttq.load("CTLRQERC77UFAAV336QG");
  } else if (window.location.host === "media.nova-beats.net") {
    ttq.load("CTLSIC3C77UDUQAQLO40");
  } else if (window.location.host === "media.nova-beats.com") {
    ttq.load("CTRPA8BC77UDUQAQUM7G");
  } else if (window.location.host === "media.novabeatsvip.com") {
    ttq.load("CTRPEHRC77UFAAV3C6UG");
  } else if (window.location.host === "media.novabeatsapp.com") {
    ttq.load("CTRP5M3C77UFAAV3C660");
  } else {
    ttq.load("");
  }
  ttq.page();
})(window, document, "ttq");

const app = createApp(App);
app.provide("bool", bool);
app.provide("home_1_slot", home_1_slot);
app.provide("home_2_slot", home_2_slot);
app.provide("home_3_slot", home_3_slot);
app.provide("home_4_slot", home_4_slot);
app.provide("playlets_1_slot", playlets_1_slot);
app.provide("playlets_2_slot", playlets_2_slot);
app.provide("play_1_slot", play_1_slot);
app.provide("play_2_slot", play_2_slot);
app.provide("adsClient", adsClient);
app.provide("gtagID", gtagID);
app.config.globalProperties.$bool = bool;

var IframeOnClick = {
  istrack: null,
  resolution: 200,
  iframes: [],
  interval: null,
  Iframe: function () {
    this.element = arguments[0];
    this.cb = arguments[1];
    this.hasTracked = false;
  },
  track: function (element, cb) {
    this.iframes.push(new this.Iframe(element, cb));
    if (!this.interval) {
      var _this = this;
      this.interval = setInterval(function () {
        _this.checkClick();
      }, this.resolution);
    }
  },
  checkClick: function () {
    if (document.activeElement) {
      var activeElement = document.activeElement;
      var tagName = activeElement.tagName;
      for (var i in this.iframes) {
        if (activeElement === this.iframes[i].element) {
          if (this.iframes[i].hasTracked == false) {
            this.iframes[i].cb.apply(window, []);
            this.iframes[i].hasTracked = true;
          }
        } else {
          this.iframes[i].hasTracked = false;
        }
      }
    }
  },
};
// 创建MutationObserver实例
const observer = new MutationObserver((mutationsList) => {
  for (let mutation of mutationsList) {
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach((node) => {
        if (node.tagName === "IFRAME") {
          // 为新的iframe元素添加点击事件监听器
          IframeOnClick.track(node, function () {
            console.log("点击了iframe");
            // 这里写一些业务逻辑，例如给变量添加状态
            IframeOnClick.istrack = true;
            ttq.track("ClickAd"); // Send event to TikTok Analytics
            gtag("event", "ClickAd", { send_to: gtagID }); // Send event to Google Analytics
          });
        }
      });
    }
  }
});

// 配置MutationObserver选项
const config = { childList: true, subtree: true };

// 启动MutationObserver
observer.observe(document.body, config);

app.use(router).use(ElementPlus).use(hls).use(firebaseApp).mount("#app");
