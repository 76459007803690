import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:channel?",
    name: "index",
    component: () => import("../views/IndexHome.vue"),
    meta: { title: "index" },
  },
  {
    path: "/:channel?/play/:id/:name/:episode",
    name: "PlayVideo",
    component: () => import("../views/PlayVideo.vue"),
    meta: { title: "PlayVideo" },
  },
  {
    path: "/:channel?/playlets",
    name: "playlets",
    component: () => import("../views/playlets.vue"),
    meta: { title: "Categories" },
  },
  {
    path: "/:channel?/audio",
    name: "AudioList",
    component: () => import("../views/AudioList.vue"),
    meta: { title: "Audio" },
  },
  {
    path: "/:channel?/audioDetails/:id/:name",
    name: "AudioDetails",
    component: () => import("../views/AudioDetails.vue"),
    meta: { title: "Audio" },
  },
  {
    path: "/:channel?/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: { title: "PrivacyPolicy" },
  },
  {
    path: "/:channel?/terms-of-service",
    name: "TermsOfService",
    component: () => import("../views/TermsOfService.vue"),
    meta: { title: "TermsOfService" },
  },
  {
    path: "/:channel?/contact-us",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
    meta: { title: "ContactUs" },
  },
  {
    path: "/:channel?/novels",
    name: "Novels",
    component: () => import("../views/novels/index.vue"),
    meta: { title: "Novels" },
  },
  {
    path: "/:channel?/novelsDetails/:id/:name",
    name: "NovelsDetails",
    component: () => import("../views/novels/novelsDetails.vue"),
    meta: { title: "NovelsDetails" },
  },
  {
    path: "/:channel?/chapter/:id/:name",
    name: "Chapter",
    component: () => import("../views/novels/chapterDetails.vue"),
    meta: { title: "chapter" },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    let title = to.meta.title;
    // 检查路由参数中是否有影片名称
    if (to.params.name) {
      title += ` - ${to.params.name}`;
    }
    document.title = title;
  } else {
    document.title = "Default Title"; // 设置默认标题
  }
  next();
});

export default router;
